import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ExpandableTextComponent} from '../../../common/expandable-text/expandable-text.component';
import {SliderComponent} from '../../../common/slider/slider.component';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {SlideComponent} from '../../../common/slider/slide/slide.component';
import {CompanyServices} from '../../../_services/company.services';
import {CompaniesResponse} from '../../../_interfaces/companies.response.interface';
import {NgIf} from '@angular/common';
import {ShopServices} from '../../../_services/shop.services';
import {MapComponentComponent} from '../../../common/map-component/map-component.component';
import {PageTitleComponent} from "../../page-title/page-title.component";

@Component({
  selector: 'app-brand-page',
  standalone: true,
    imports: [
        BaseComponent,
        ExpandableTextComponent,
        SliderComponent,
        RouterLink,
        SlideComponent,
        NgIf,
        MapComponentComponent,
        PageTitleComponent
    ],
  templateUrl: './brand-page.component.html',
  styleUrl: './brand-page.component.css'
})
export class BrandPageComponent implements OnInit {
  protected readonly Array = Array;
  public company!: CompaniesResponse;
  public markers:any = [];
  public showMap: boolean = false;

  constructor(private companyService: CompanyServices, private route: ActivatedRoute, private router: Router, private shopServices: ShopServices) {
  }

  ngOnInit(): void {
    const selectedName = this.route.snapshot.queryParams['name'];
    this.companyService.getCompanies(null, selectedName).subscribe({
      next: data => {
        if (data && data.length > 0) {
          this.company = data[0];
          return;
        }
        this.router.navigateByUrl('/brands');
      }
    });


    this.shopServices.getShops().subscribe({
      next: data => {
        if (data && data.length > 0) {
          data.forEach(shop => {
            const marker = {
              position: {lat: parseFloat(shop.lat), lng: parseFloat(shop.lng)},
              title: shop.name,
              options: {},
              shop: shop,
            };
            this.markers.push(marker);
          })
        }
      }
    })
  }
}
