import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import moment from 'moment/moment';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsCustom {

  public static adultOnly(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }

      if ((moment()).diff(moment(value), 'years') >= 18) {
        return null;
      }

      return {
        needAdult: true
      };
    }
  }
}
