<app--unauthorized-base>
  <h1 class="text-2xl font-bold mb-0.5">Załóż konto</h1>
  <h2 class="text-gray-light">Podaj swój adres e-mail</h2>

  <form novalidate [formGroup]="this.registerForm" (submit)="submit($event)" class="grow flex flex-col">
    <div class="mt-8 grid md:grid-cols-2 gap-y-8">
      <app-form-control-text-field class="w-10/12" name="first-name" [control]="registerForm.controls['name']" label="Imię" controlError="Imię jest wymagane" />
      <app-form-control-text-field class="w-10/12" name="last-name" [control]="registerForm.controls['surname']" label="Nazwisko" controlError="Nazwisko jest wymagane" />
      <app-form-control-text-field class="w-10/12" name="email" [control]="registerForm.controls['email']" label="Adres e-mail" [controlError]="phoneControlError" />
    </div>
    <br /><br />
    <app-form-alert text="Przepraszamy, ale nie możemy założyć konta na podany numer telefonu." *ngIf="registerFormError"></app-form-alert>
    <button #submitButton type="submit" class="btn btn-secondary btn-text-shadow mt-auto w-full">dalej</button>
  </form>
</app--unauthorized-base>
