import {Component, ElementRef, OnInit, viewChild} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {AuthServices} from '../../_services/auth.services';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {FormControlTextFieldComponent} from '../../common/form-control-text-field/form-control-text-field.component';
import {RegisterResponse} from '../../_interfaces/register.response.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {NgIf} from '@angular/common';
import {FormAlertComponent} from '../../common/form-alert/form-alert.component';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    BaseComponent,
    FormControlTextFieldComponent,
    ReactiveFormsModule,
    NgIf,
    FormAlertComponent
  ],
  templateUrl: './register-phone.component.html',
  styleUrl: './register-phone.component.css',
  providers: [AuthServices]
})
export class RegisterPhoneComponent implements OnInit {
  protected submitButton = viewChild<ElementRef>('submitButton');
  public registerForm: any;
  public phoneControlError: string = '';
  public registerFormError: string = '';
  public inviteCode: string = '';

  constructor(public authServices: AuthServices, formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute) {
    this.registerForm = formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{5}$')]],
    })
    this.registerForm.valueChanges.subscribe({
      next: (value: string) => {
        this.validation()
      }
    })
  }

  ngOnInit(): void {
    sessionStorage.removeItem('registration')
    sessionStorage.removeItem('token')
    this.inviteCode = this.route.snapshot.queryParams['invite'] ?? '';
  }

  submit(event: SubmitEvent) {
    event.preventDefault();
    for (const i in this.registerForm.controls) {
      this.registerForm.controls[i].markAsTouched();
      this.registerForm.controls[i].markAsDirty();
      this.validation()
    }
    if (this.registerForm.status === 'VALID') {
      this.submitButton()!.nativeElement.classList.toggle('opacity-30');

      let data = this.registerForm.value;
      data.inviteCode = this.inviteCode;

      this.authServices.registerByPhone(data).subscribe({
        next: (value: RegisterResponse) => {
          sessionStorage.setItem('registration', JSON.stringify({phone: this.registerForm.controls['phone'].value}));
          this.router.navigate(['/register/auth-code']);
          this.submitButton()!.nativeElement.classList.toggle('opacity-30');},
        error: (error: any) => {
          this.registerFormError = error;
          this.submitButton()!.nativeElement.classList.toggle('opacity-30');
        }
      });
    }
  }

  private validation() {
    let phoneErrors = this.registerForm.controls['phone'].errors;

    if (phoneErrors?.required === true) {
      this.phoneControlError = "Numer telefonu jest wymagany"
    }
    if (phoneErrors?.pattern instanceof Object) {
      this.phoneControlError = "To nie jest poprawny numer telefonu komórkowego, przykładowy: +48501501501"
    }
    if (phoneErrors === null) {
      this.phoneControlError = "";
    }
  }
}
