import {Component, ElementRef, Input, viewChild} from '@angular/core';
import {Customer} from '../../classes/customer';
import { Creator, ErrorCorrectionLevel, MaskPattern, Renderer } from 'easy-qrcode';
import {Router} from '@angular/router';
import {LocationStrategy} from '@angular/common';

@Component({
  selector: 'app-qrcode',
  standalone: true,
  imports: [],
  templateUrl: './qrcode.component.html',
  styleUrl: './qrcode.component.css'
})
export class QrcodeComponent {
  protected qrCode = viewChild<ElementRef>(('qrCode'));
  protected qrCodeEl: HTMLElement | undefined;
  protected qrHeader = viewChild<ElementRef>(('qrHeader'));
  protected qrHeaderEl: HTMLElement | undefined;
  @Input() user: Customer = new Customer();
  public matrix:any;
  private readonly baseUrl: string;

  constructor(router: Router, locationStrategy:LocationStrategy) {
    const parsedUrl = new URL(window.location.href);
    this.baseUrl = parsedUrl.origin;
  }

  ngOnInit() {
    const qrRenderer = new Renderer();
    const qrCreator = new Creator({
      version: 4,
      enableECI: false,
      errorCorrectionLevel: ErrorCorrectionLevel.M,
      maskPattern: MaskPattern.PATTERN000,
    });
    qrCreator.add(this.baseUrl + '/user?club=' + this.user.clubId.toString());
    this.matrix = qrCreator.create().getMatrix()

    const canvas = document.getElementById('myCanvas');
    qrRenderer.drawCanvas(this.matrix, canvas as HTMLCanvasElement);

    this.qrCodeEl = this.qrCode()!.nativeElement as HTMLElement;
    this.qrHeaderEl = this.qrHeader()!.nativeElement as HTMLElement;
  }
}
