<app--authorized-base pageWrapperClasses="welcome-page">
  <div #qrHeader class="max-h-48">
    <div class="absolute top-0 left-0 w-full h-[510px] -z-10">
    </div>
    <div class="float-left pr-10 pb-10">
      <svg id="barcode"></svg>
    </div>
    <div class="float-left pt-5">
      <h1 class="text-2xl font-bold text-white mb-3">Cześć {{ this.customer.name }}!</h1>
      <h2 class="text-white font-light mb-7 text-xl">Pokaż swój kod sprzedawcy<br /> aby skorzystać z promocji</h2>
    </div>
  </div>
  <div *ngIf="campaigns" class="bg-white w-11/12 md:w-[52rem] rounded-2xl mb-10">
    <h2 class="font-bold text-2xl text-qsq-medium w-full pt-16 pb-10 pr-20 pl-20">Aktualne promocje</h2>
    <div class="max-w-[746px] mx-auto">
      <app-slider [slidePxWidth]="336" [slidesPerPage]="2" [slidePxGap]="8">
        @for (i of this.campaigns; track $index) {
          <app-slide>
            <div class="slide">
              <img src="/assets/{{i.company.logo.filePath}}" alt="Logo {{i.company.name}}" class="mx-auto mb-5" />
              <div class="group relative bg-gray-lighter rounded-3xl w-80 flex flex-col items-center py-8 px-10 snap-center shrink-0">
                <img src="/assets/images/main/slider/placeholder.png" alt="" />
                <p class="text-qsq-medium mt-4 mb-6">{{i.campaign.shortText}}</p>
                <p class="text-qsq-medium mt-4 mb-6">{{i.campaign.longText}}</p>
                <button *ngIf="i.codes.length == 0" (click)="takeCoupon(i.id)" class="btn btn-primary w-full !bg-qsq-medium !text-white">wykorzystaj</button>
                <div *ngIf="i.codes.length > 0" class="text-center">
                  kod aktywowano
                </div>
              </div>
            </div>
          </app-slide>
        }
      </app-slider>
    </div>
  </div>
</app--authorized-base>
