<app--authorized-base-user pageWrapperClasses="welcome-page">
  <div id="heading-background" class="absolute top-0 left-0 w-full h-[510px] -z-10">
    <img src="/assets/images/main/background.png" class="object-cover w-full h-full object-left"  alt=""/>
  </div>
  <br />
  <div class="w-10/12 md:w-5/12 flex flex-row text-center z-10 mx-auto">
    <div class="flex flex-col w-full relative pt-5">
      <form [formGroup]="this.searchForm">
        <input formControlName="phone" id="tel" type="text" name="tel" class="peer w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-brand-medium" placeholder="Numer telefonu" />
        <label for="tel" class="absolute left-0 -top-3.5 text-sm transition-all peer-placeholder-shown:text-base
                            peer-placeholder-shown:text-gray-light peer-placeholder-shown:top-2 peer-focus:-top-3.5
                            peer-focus:text-gray-600 peer-focus:text-sm"
        >
          Numer telefonu/Adres e-mail
        </label>
        <small *ngIf="this.searchFormError !== '' || (this.searchForm.controls['phone'].invalid && this.searchForm.controls['phone'].dirty)" class="text-red-500">{{ searchFormError }}</small>
      </form>
    </div>
    <button (click)="search()" class="btn btn-primary btn-sm">Szukaj</button>
  </div>
  <br />
  <div class="w-11/12" *ngIf="this.clubId">
    <div class="flex justify-center">
      <svg id="barcode"></svg>
    </div>
    <br />
    <br />
    <section class="grid grid-cols-1 lg:grid-cols-2 justify-items-stretch">
      <div class="w-11/12">
        <h3 class="text-center font-bold text-lg text-gray-dark mb-5">Lista aktywnych kodów</h3>
        <div *ngIf="this.codes">
          <div *ngFor="let code of this.codes" class="mb-2 mt-2 group block mx-auto rounded-lg p-3 bg-white ring-1 shadow-lg space-y-3 hover:ring-2 hover:ring-brand-light">
            <div class="flex flex-col items-center space-x-3">
              <h3 class="text-slate-900 text-sm">Kampania: <strong>{{code.campaignCompany.campaign.shortText}}</strong></h3>
              <h3 class="text-slate-900 text-sm">Kod: <strong>{{code.code}}</strong></h3>
            </div>
          </div>
        </div>
      </div>
      <div class="w-11/12">
        <h3 class="text-center font-bold text-lg text-gray-dark mb-5">Lista paragonów</h3>
        <div *ngIf="this.invoices">
          <div *ngFor="let invoice of this.invoices" class="mb-2 mt-2 group block mx-auto rounded-lg p-3 bg-white ring-1 shadow-lg space-y-3 hover:ring-2 hover:ring-brand-light">
              <div class="items-center">
                <p class="text-slate-900 text-sm">Paragon numer: <strong>{{ invoice.number }}</strong>, Sprzedano: <strong>{{ moment(invoice.sellDate).format('YYYY-MM-DD') }}</strong></p>
                <p *ngIf="invoice.invoiceType == 'correction' && invoice.parent == undefined" class="text-slate-900 text-sm">Korekta</p>
                <p *ngIf="invoice.invoiceType == 'correction' && invoice.parent" class="text-slate-900 text-sm">Korekta do: <strong>{{ invoice.parent.number }}</strong></p>
              </div>
              <p>Pozycje:</p>
              <table *ngIf="invoice.invoiceElements" class="table-auto w-full">
                <tr *ngFor="let invoiceElement of invoice.invoiceElements">
                  <td>{{invoiceElement.name}}</td>
                  <td>{{invoiceElement.sku}}</td>
                  <td>{{invoiceElement.quantity}}</td>
                </tr>
              </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</app--authorized-base-user>
