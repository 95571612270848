import {Component, ElementRef, OnInit, viewChild} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {PageTitleComponent} from '../page-title/page-title.component';
import {FormBuilder, Validators} from '@angular/forms';
import {FormControlTextFieldComponent} from '../../common/form-control-text-field/form-control-text-field.component';
import {CustomerServices} from '../../_services/customer.services';
import {FormControlDateFieldComponent} from '../../common/form-control-date-field/form-control-date-field.component';
import moment from 'moment';
import {FormAlertComponent} from '../../common/form-alert/form-alert.component';
import {NgIf} from '@angular/common';
import {ValidatorsCustom} from '../../_utils/validators-custom.service';
import {RouterLink} from '@angular/router';
import {
  GooglePlacesAutocompleteComponent
} from '../../common/google-places-autocomplete/google-places-autocomplete.component';

@Component({
  selector: 'app-details-change',
  standalone: true,
  imports: [
    BaseComponent,
    PageTitleComponent,
    FormControlTextFieldComponent,
    FormControlDateFieldComponent,
    FormAlertComponent,
    NgIf,
    RouterLink,
    GooglePlacesAutocompleteComponent
  ],
  templateUrl: './details-change.component.html',
  styleUrl: './details-change.component.css'
})
export class DetailsChangeComponent implements OnInit {

  protected submitButton = viewChild<ElementRef>('submitButton');
  protected postCodeEl!: HTMLInputElement;
  protected cityEl!: HTMLInputElement;
  protected readonly Array = Array;
  public detailChangeForm: any;
  private customerId: string = '';
  public formSuccess: boolean = false;
  public birthDateError: string = '';

  get f() {
    return this.detailChangeForm.controls
  }

  constructor(formBuilder: FormBuilder, private customerService: CustomerServices) {
    this.detailChangeForm = formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      street: ['', Validators.required],
      city: ['', Validators.required],
      postCode: ['', [Validators.required, Validators.pattern('^\\d{2}-\\d{3}$')]],
      birthDate: ['', [Validators.required, ValidatorsCustom.adultOnly()]],
      email: [{value: '', disabled: true}],
      phoneNumber: [{value: '', disabled: true}],
    });
    this.detailChangeForm.valueChanges.subscribe( () => {
      this.validation();
    })
  }


  ngAfterViewInit() {
    this.postCodeEl = document.getElementById('postCode') as HTMLInputElement;
    this.cityEl = document.getElementById('city') as HTMLInputElement;
  }

  validation() {
    let birthCodeErrors = this.detailChangeForm.controls['birthDate'].errors;
    if (birthCodeErrors?.required === true) {
      this.birthDateError = "Data urodzenia jest wymagana"
    }
    if (birthCodeErrors?.needAdult === true) {
      this.birthDateError = "Tylko osoba która ukończyła 18 lat może być członkiem klubu"
    }
    if (birthCodeErrors === null) {
      this.birthDateError = "";
    }
  }
  ngOnInit(): void {
    this.customerService.getMe().subscribe(
      data => {
        this.f.name.setValue(data.name);
        this.f.surname.setValue(data.surname);
        this.f.city.setValue(data.city);
        this.f.street.setValue(data.street);
        this.f.postCode.setValue(data.postCode);
        if (data.birthDate != null) {
          this.f.birthDate.setValue((moment(data.birthDate)).format('YYYY-MM-DD'));
        }
        this.f.email.setValue(data.email);
        this.f.phoneNumber.setValue(data.phoneNumber);
        this.customerId = data.id
      }
    )
  }

  setAddressFromGooglePlace(place: google.maps.places.PlaceResult) {
    const streetName = GooglePlacesAutocompleteComponent.findAddressComponent(place, 'route') ?? "";
    const streetNumber = GooglePlacesAutocompleteComponent.findAddressComponent(place, 'street_number') ?? "";
    this.f.street.setValue(streetName + " " + streetNumber);
    this.f.street.markAsTouched();
    this.f.street.markAsDirty();

    this.f.postCode.setValue(GooglePlacesAutocompleteComponent.findAddressComponent(place, 'postal_code'));
    this.f.postCode.markAsTouched();
    this.f.postCode.markAsDirty();
    this.postCodeEl.setAttribute("disabled", '');

    this.f.city.setValue(GooglePlacesAutocompleteComponent.findAddressComponent(place, 'locality'));
    this.f.city.markAsTouched();
    this.f.city.markAsDirty();
    this.cityEl.setAttribute("disabled", '');
  }

  onSubmit(event: Event) {
    for (const i in this.detailChangeForm.controls) {
      if (this.detailChangeForm.controls[i].disabled !== true) {
        this.detailChangeForm.controls[i].markAsTouched();
        this.detailChangeForm.controls[i].markAsDirty();
        this.validation()
      }
    }
    this.formSuccess = false;
    if (this.detailChangeForm.status === 'VALID') {
      this.submitButton()!.nativeElement.classList.toggle('opacity-30');
      this.customerService.patchCustomer(this.detailChangeForm.value, this.customerId).subscribe({
        next: () => {
          this.submitButton()!.nativeElement.classList.toggle('opacity-30');
          this.formSuccess = true;
        }
      })
    }
  }
}
