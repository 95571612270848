import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import { QrcodeComponent } from '../qrcode/qrcode.component';
import {SliderComponent} from '../../common/slider/slider.component';
import {SlideComponent} from '../../common/slider/slide/slide.component';
import {CampaignsService} from '../../_services/campaigns.service';
import {CampaignResponse} from '../../_interfaces/campaign.response.interface';
import {NgIf} from '@angular/common';
import {CodesService} from '../../_services/codes.service';
import {CustomerServices} from '../../_services/customer.services';
import {CacheService} from '../../_services/cache.service';
import {Customer} from '../../classes/customer';
import JsBarcode from 'jsbarcode';

@Component({
  selector: 'app-welcome',
  standalone: true,
  imports: [
    BaseComponent,
    QrcodeComponent,
    SliderComponent,
    SlideComponent,
    NgIf
  ],
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.css'
})
export class WelcomeComponent implements OnInit {

  protected readonly Array = Array;
  public campaigns!: CampaignResponse[];
  public customer!: Customer;

  constructor(
    public customerService:CustomerServices,
    private campaignService:CampaignsService,
    private codesService:CodesService,
    private cacheService: CacheService) {
  }

  ngOnInit(): void {
    this.customer = this.customerService.getCustomer();
    JsBarcode('#barcode', String(this.customer.clubId));
    this.cacheService.cacheObservable('campaigns', this.campaignService.getCampaigns(true)).subscribe({
      next: data => {
        this.campaigns = data;
      }
    });
  }

  takeCoupon(id: string) {
    this.codesService.postCodes({
      campaignCompany: "/api/campaign_companies/" + id,
    }).subscribe({
      next: data => {
        this.campaignService.getCampaigns(true).subscribe({
          next: data => {
            this.campaigns = data;
          }
        })
      }
    });
  }
}
