import {Component, ElementRef, Input, viewChild} from '@angular/core';
import {RouterLink} from '@angular/router';
import {NotificationsService} from '../../_services/notifications.service';
import {NotificationsResponse} from '../../_interfaces/notifications.response';
import {NgIf} from '@angular/common';
import {ThemingComponent} from '../../common/theming/theming.component';

@Component({
  selector: 'app--authorized-base',
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    ThemingComponent
  ],
  templateUrl: './base.component.html',
  styleUrl: './base.component.css'
})
export class BaseComponent {
  protected menuDrawer = viewChild<ElementRef>('menuDrawer');
  protected menuDrawerEl: HTMLElement | undefined;
  @Input() headerClasses = '';
  @Input() pageWrapperClasses = '';
  public data!: NotificationsResponse;
  private interval: any;

  constructor(private notificationsService: NotificationsService) {
  }

  refreshData(){
    this.notificationsService.fetchAll().subscribe();
  }

  ngOnInit() {
    this.menuDrawerEl = this.menuDrawer()!.nativeElement as HTMLElement;

    this.notificationsService.data$.subscribe(data => { // subscribe once to the data stream
      this.data = data;
    });
    this.refreshData();
    this.interval = setInterval(() => {
      this.refreshData();
    }, 20000);
  }

  toggleMenu(event: Event) {
    (event.currentTarget as HTMLElement).classList.toggle('menu-active');
    this.menuDrawerEl!.classList.toggle('translate-x-full');
  }
}
