<app--authorized-base pageWrapperClasses="welcome-page" class="brand-qsq">
  <main class="bg-white w-[52rem] rounded-2xl mb-10 py-5 px-10 flex flex-col gap-y-5 pb-20 grow max-w-full">
    <app-page-title label="Zaproś znajomych" routerLinkUrl="/welcome" />
    <div class="flex h-96 flex-col" *ngIf="!inviteFormSuccess">
      <form novalidate [formGroup]="this.inviteForm" class="grow flex flex-col">
        <div class="grid md:grid-cols-2 gap-y-8 mb-20">
          <app-form-control-text-field class="w-10/12" name="first-name" [control]="inviteForm.controls['name']" label="Imię" controlError="Imię jest wymagane" />
          <app-form-control-text-field class="w-10/12" name="last-name" [control]="inviteForm.controls['surname']" label="Nazwisko" controlError="Nazwisko jest wymagane" />
          <app-form-control-text-field class="w-10/12" name="email" [control]="inviteForm.controls['email']" label="adres e-mail" controlError="Adres email jest wymagany" />
        </div>
      </form>
      <div class="place-items-center justify-end">
        <button (click)="send()" [disabled]="this.submitted" class="md:w-6/12 btn btn-secondary w-full disabled:opacity-30 disabled:cursor-not-allowed">Wyślij zaproszenie</button>
      </div>
    </div>
    <div class="flex h-96 flex-col" *ngIf="inviteFormSuccess">
      <div class="place-items-center"><img class="brand-filter" src="/assets/images/check.svg" alt="Concierge" /></div>
      <div class="place-items-center mt-10 mb-20 grow flex flex-col">
        <h1 class="text-gray-500 font-bold text-2xl">Gratulacje!</h1>
        <p class="text-gray-light font-light leading-relaxed">Twoje zaproszenie do klubu... zostało</p>
        <p class="text-gray-light font-light leading-relaxed">pomyślnie wysłane do {{firstName}} {{surName}}</p>
        <p class="text-gray-light font-light leading-relaxed">Dziękujemy!</p>
      </div>
      <div class="place-items-center justify-end mb-3">
        <a (click)="reloadCurrentRoute()" routerLink="/invite" class="md:w-6/12 btn btn-secondary btn-brand w-full">zaproś kolejną osobę</a>
      </div>
      <div class="place-items-center justify-end">
        <a routerLink="/welcome" class="md:w-6/12 btn btn-secondary w-full">wróc do strony głównej</a>
      </div>
    </div>
  </main>
</app--authorized-base>
