import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsentsServices {
  constructor(private http: HttpClient) {
  }

  public getConsents() {
    return this.http.get(environment.apiUrl + '/consents');
  }
}
