import {Component, ElementRef, viewChild} from '@angular/core';
import {BaseComponent} from "../base/base.component";
import {NgIf} from '@angular/common';
import {PinPadComponent} from '../../common/pin-pad/pin-pad.component';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {FormControlTextFieldComponent} from '../../common/form-control-text-field/form-control-text-field.component';
import {AuthServices} from '../../_services/auth.services';
import {Router} from '@angular/router';
import {FormAlertComponent} from '../../common/form-alert/form-alert.component';
import {CustomerServices} from '../../_services/customer.services';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    BaseComponent,
    NgIf,
    PinPadComponent,
    ReactiveFormsModule,
    FormControlTextFieldComponent,
    FormAlertComponent
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent {
  protected submitButton = viewChild<ElementRef>('submitButton');
  public sendAuthCode = false;
  public pinValue: string = '';
  public pinPadError: string = '';
  public fullValidation: any;
  public loginForm: any;
  public loginFormError: string = '';
  public confirmError: string = '';
  public resendAuthCodeStatus = false;

  constructor(formBuilder: FormBuilder, private authService: AuthServices, private router: Router, private customerService: CustomerServices) {
    this.loginForm = formBuilder.group({
      emailOrPhone: ['', [Validators.compose([Validators.required, Validators.email, Validators.pattern('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{5}$')])]],
    })
    this.loginForm.valueChanges.subscribe({
      next: () => {
        this.validation()
        this.sendAuthCode = false;
      }
    })
  }

  submit(event: SubmitEvent) {
    event.preventDefault();
    for (const i in this.loginForm.controls) {
      this.loginForm.controls[i].markAsTouched();
      this.loginForm.controls[i].markAsDirty();
      this.validation()
    }

    if (this.loginFormError === "") {
      if (!this.sendAuthCode) {
        this.submitButton()!.nativeElement.classList.toggle('opacity-30');
        this.authService.authRequest(this.loginForm.value).subscribe({
          complete: () => {
            this.submitButton()!.nativeElement.classList.toggle('opacity-30');
          }
        });
        this.sendAuthCode = true;
      } else {
        this.submitButton()!.nativeElement.classList.toggle('opacity-30');
        this.fullValidation = true;
        if (this.pinPadError === "") {
          this.authService.authConfirm({
            emailOrPhone: this.loginForm.controls['emailOrPhone'].value,
            pin: this.pinValue
          }).subscribe({
            next: (value) => {
              this.authService.storeToken(value.token);
              this.customerService.loginWithToken(value.token);
              this.router.navigate(['/welcome']);
              this.submitButton()!.nativeElement.classList.toggle('opacity-30');
            },
            error: () => {
              this.confirmError = "Niepoprawne dane do logowania.";
              this.submitButton()!.nativeElement.classList.toggle('opacity-30');
            }
          });
        }
      }
    }
  }

  private validation() {
    this.loginFormError = "";

    let loginErrors = this.loginForm.controls['emailOrPhone'].errors;

    if (loginErrors?.required === true) {
      this.loginFormError = "Numer telefonu lub adres email jest wymagany"
    }

    if (loginErrors?.email === true && loginErrors?.pattern !== undefined) {
      this.loginFormError = "Numer telefonu lub adres email jest wymagany"
    }
  }
  resendAuthCode() {
    this.authService.resendAuthCode({emailOrPhone: this.loginForm.controls['emailOrPhone'].value}).subscribe({
      next: data => {
        this.resendAuthCodeStatus = true;
        setTimeout(() => {
          this.resendAuthCodeStatus = false;
        }, 60000);
      }
    })
  }
}
