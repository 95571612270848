<app--unauthorized-base>
  <div class="md:w-8/12 md:ml-5">
    <h1 class="text-2xl font-bold mb-0.5">Załóż konto</h1>
    <span class="text-gray-light">Wybierz bezpieczny sposób logowania</span>
    <div class="flex flex-col justify-items-end md:w-8/12 mt-12">
      <a routerLink="phone" class="btn btn-secondary bg-slate-100 font-bold text-brand-medium text-xl">
        Numer telefonu
      </a>
      <br />
      <a routerLink="email" class="btn btn-secondary bg-slate-100 font-bold text-brand-medium text-xl">
        Adres e-mail
      </a>
    </div>
  </div>
</app--unauthorized-base>
