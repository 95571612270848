import {Component, ElementRef, EventEmitter, Input, Output, viewChild} from '@angular/core';

@Component({
  selector: 'app-slide',
  standalone: true,
  imports: [],
  templateUrl: './slide.component.html',
  styleUrl: './slide.component.css'
})
export class SlideComponent {
  @Output() onEnterScreen: EventEmitter<any> = new EventEmitter;
  @Input() onEnterScreenCallbackArguments: Array<string> = [];
  public content = viewChild<ElementRef>('content');
  width = 0;
  gap = 0;

  public slideEnteredView() {
    this.onEnterScreen.emit(this.onEnterScreenCallbackArguments);
  }
}
