<app--authorized-base pageWrapperClasses="welcome-page" headerClasses="!drop-shadow-none">
  <div class="bg-white w-[52rem] rounded-2xl mb-10 px-12 flex flex-col justify-center gap-y-5 pb-20 max-w-full">
    <app-page-title label="Quiosque" routerLinkUrl="/brands" />
  <div class="mt-10">
    <img *ngIf="company" src="/assets/{{company.logo.filePath}}" alt="" class="block mx-auto mb-20" />

    <div class="absolute bottom-0 left-0 w-full drop-shadow-[0px_-3px_3px_rgba(0,0,0,0.1)]">
      <div class="bg-white w-full h-12 " style="clip-path: ellipse(60% 100% at 50% 100%);"></div>
    </div>
  </div>
  <div class="max-w-[48rem] w-full px-8">
    <section id="brand-page__text-intro">
      <h1 class="text-gray-dark text-lg font-bold mb-3">Bio</h1>
      <app-expandable-text *ngIf="company" maxHeight="120px">
        <p class="text-gray-dark mb-4">
          {{company.description}}
        </p>
        <p class="text-gray-dark mb-4">
          {{company.description}}
        </p>
      </app-expandable-text>
    </section>
    <section id="brand-page__stores" class="flex items-center justify-center gap-x-5 my-8">
      <a href="javascript:void(0)" (click)="this.showMap = !this.showMap" class="btn btn-secondary gap-x-2.5 grow">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.81 27.675" class="w-5 fill-brand-medium">
          <path d="M11.689,27.053c2.78-3.479,9.121-11.912,9.121-16.648a10.405,10.405,0,0,0-20.81,0c0,4.736,6.341,13.169,9.121,16.648a1.637,1.637,0,0,0,2.569,0ZM10.405,6.937a3.468,3.468,0,1,1-3.468,3.468A3.468,3.468,0,0,1,10.405,6.937Z" />
        </svg>
        <span>lokalizacje sklepów</span>
      </a>

      <a *ngIf="company" target="_blank" href="{{company.www}}" class="btn btn-secondary rounded-full bg-gradient-to-b from-brand-dark to-brand-light w-[76px]">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.637 36.637" class="fill-white size-9">
          <path d="M18.319,34.348c1.2,0,2.891-1.03,4.429-4.1a21.8,21.8,0,0,0,1.725-5.059H12.165a21.8,21.8,0,0,0,1.725,5.059C15.428,33.317,17.124,34.348,18.319,34.348ZM11.757,22.9H24.881a33.467,33.467,0,0,0,.308-4.58,33.467,33.467,0,0,0-.308-4.58H11.757a34.235,34.235,0,0,0,0,9.159Zm.408-11.449H24.473A21.8,21.8,0,0,0,22.748,6.39c-1.538-3.07-3.234-4.1-4.429-4.1s-2.891,1.03-4.429,4.1A21.8,21.8,0,0,0,12.165,11.449Zm15.027,2.29a36.423,36.423,0,0,1,.286,4.58,36.423,36.423,0,0,1-.286,4.58h6.5a16.144,16.144,0,0,0,.665-4.58,15.893,15.893,0,0,0-.665-4.58Zm5.617-2.29a16.056,16.056,0,0,0-9.367-8.322,21.234,21.234,0,0,1,3.378,8.322h5.989Zm-22.977,0A21.188,21.188,0,0,1,13.21,3.127a16.028,16.028,0,0,0-9.374,8.322h6Zm-6.87,2.29a16.144,16.144,0,0,0-.665,4.58,15.893,15.893,0,0,0,.665,4.58H9.446a36.781,36.781,0,0,1,0-9.159H2.955ZM23.435,33.51A16.08,16.08,0,0,0,32.8,25.188H26.813A21.188,21.188,0,0,1,23.435,33.51Zm-10.233,0a21.342,21.342,0,0,1-3.378-8.322H3.835A16.056,16.056,0,0,0,13.2,33.51Zm5.116,3.127A18.319,18.319,0,1,1,36.637,18.319,18.319,18.319,0,0,1,18.319,36.637Z" />
        </svg>
      </a>
    </section>
    <app-map *ngIf="this.showMap" [markers]="this.markers" />
    <section class="flex flex-col">
      <h3 class="font-bold text-lg text-gray-dark mb-5">Aktualne promocje w Quiosque</h3>
      <app-slider [slidePxGap]="24" [slidePxWidth]="365" [slidesPerPage]="1" [stretchGap]="false">
        @for (i of Array(2); track $index) {
          <app-slide>
            <div class="group relative bg-gray-lighter rounded-2xl flex items-stretch">
              <div class="flex shrink-0 items-center justify-center bg-brand-medium text-white text-2xl font-bold w-28 rounded-l-2xl">
                -20%
              </div>
              <div class="absolute left-28 -translate-x-1/2 top-0 h-full flex flex-col justify-center gap-y-2.5 z-10">
                @for (i of Array(8); track $index) {
                  <span class="size-3 aspect-square bg-[#F8F8F8] rounded-full"></span>
                }
              </div>
              <div class="slider-card-rotate relative flex flex-col justify-center grow text-gray-dark gap-y-2 pl-7 py-12 pr-2">
                <p>Zniżka na całą <br>kolekcję jesień / zima</p>
                <p class="font-bold">sklepy stacjonarne</p>

                <div class="opacity-0 transition-all absolute top-0 left-0 w-full h-full flex flex-col justify-center text-white gap-y-2 pl-7 py-12 pr-2 bg-brand-medium rounded-r-2xl">
                  <p>Zeskanuj swój kod QR podczas zakupów w sklepie stacjonarnym, a rabat naliczy się automatycznie</p>
                </div>
              </div>
            </div>
          </app-slide>
        }
      </app-slider>
    </section>
  </div>
  <div class="bg-white px-8 mt-auto w-[48rem] max-w-full">
    <a routerLink="/brands" class="border-t border-gray-dark/40 flex justify-center items-center gap-x-1 text-gray-dark font-bold text-lg pt-5 pb-9">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" class="size-6 stroke-black">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
      </svg>
      <span>wróć</span>
    </a>
  </div>
  </div>
</app--authorized-base>
