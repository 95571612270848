import {Injectable} from '@angular/core';
import {User} from '../classes/user';
import {HttpClient} from '@angular/common/http';
import { environment} from '../../environments/environment';
import {AuthRequestResponse} from '../_interfaces/auth.request.response.interface';
import {AuthConfirmResponse} from '../_interfaces/auth.confirm.response.interface';
import {AuthRequestRequest} from '../_interfaces/auth.request.request.interface';
import {AuthConfirmEmailRequest} from '../_interfaces/auth.confirm.email.request.interface';
import {jwtDecode} from 'jwt-decode';
import {CustomJwtPayload} from '../_interfaces/custom.jwt.payload';

@Injectable({
  providedIn: 'root'
})
export class UserServices {
  private user: User = new User();

  constructor(private http: HttpClient) {}

  authRequest(data: AuthRequestRequest) {
    return this.http.post<AuthRequestResponse>(environment.apiUrl + '/users/auth-request', data);
  }

  authConfirm(data: AuthConfirmEmailRequest) {
    return this.http.post<AuthConfirmResponse>(environment.apiUrl + '/users/auth-confirm', data);
  }

  getUser() {
    return this.user;
  }

  loginWithToken(token: string) {
    if (token === '') {
      return;
    }
    this.setUser(token);
  }

  getToken() {
    return localStorage.getItem('user_token') ?? null;
  }

  private setUser(token: string) {
    if (this.user.email === '') {

      let user = new User();
      let local = jwtDecode(token) as CustomJwtPayload;

      user.email = local.username;
      this.user = user;
      this.user.storeToken(token);
    }
  }
}
