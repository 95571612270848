import {Component} from '@angular/core';
import {RouterLink} from '@angular/router';
import {SliderComponent} from '../../common/slider/slider.component';
import {SlideComponent} from '../../common/slider/slide/slide.component';
import {ThemingComponent} from '../../common/theming/theming.component';

@Component({
  selector: 'app-index',
  standalone: true,
  imports: [
    RouterLink,
    SliderComponent,
    SlideComponent,
    ThemingComponent
  ],
  templateUrl: './index.component.html',
  styleUrl: './index.component.css'
})
export class IndexComponent {

  protected readonly Array = Array;

  public changeTheme(event: any) {
    const changeTheme = new CustomEvent('changeTheme', {
      detail: {
        theme: event[0]
      }
    });

    document.body.dispatchEvent(changeTheme);
  }
}
