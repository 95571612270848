<app-theming>
  <div id="page-wrapper" class="relative bg-gradient-to-b min-h-screen flex items-center flex-col">
    <header class="md:w-[56rem] max-w-full">
      <div class="w-full h-36 flex items-center justify-between px-9 pt-12">
        <a href="/" class="block">
          <img src="/assets/images/logo-concierge.svg" alt="Konscierge" />
        </a>
        <button type="button" (click)="goBack()" title="Wróć" class="link-button">
          <svg width="11" height="15" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.32005 9.38536C0.872808 8.93811 0.872808 8.2118 1.32005 7.76455L8.18968 0.894924C8.63692 0.447681 9.36324 0.447681 9.81048 0.894924C10.2577 1.34217 10.2577 2.06849 9.81048 2.51573L3.74946 8.57674L9.80691 14.6378C10.2541 15.085 10.2541 15.8113 9.80691 16.2586C9.35966 16.7058 8.63334 16.7058 8.1861 16.2586L1.31647 9.38893L1.32005 9.38536Z" fill="white"/>
          </svg>
          wróć
        </button>
      </div>
    </header>
    <div id="page-wrapper-inner" class="relative flex flex-col grow pb-11 md:w-[54rem] max-w-full">
      <main class="relative bg-white drop-shadow-xl rounded-2xl px-24 pt-28 pb-8 mx-5 h-full grow flex flex-col transition-all z-10">
        <ng-content></ng-content>
      </main>
    </div>

  </div>
</app-theming>
