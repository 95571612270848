<app--unauthorized-base>
  <h1 class="text-2xl font-bold mb-0.5">Dokończ rejestrację</h1>
  <form novalidate (submit)="submit($event)" [formGroup]="this.finalizeForm" class="grow flex flex-col">
    <div class="mt-3 flex flex-col gap-y-6">
      <app-google-places-autocomplete [control]="finalizeForm.controls['street']" name="street" label="Ulica" controlError="Ulica jest wymagana" (choosePlaceEvent)="setAddressFromGooglePlace($event)" />
      <app-form-control-text-field [control]="finalizeForm.controls['postCode']" name="postCode" label="Kod pocztowy" [controlError]="this.postCodeError" />
      <app-form-control-text-field [control]="finalizeForm.controls['city']" name="city" label="Miasto" controlError="Miasto jest wymagane" />
      <app-form-control-date-field [control]="finalizeForm.controls['birthDate']" name="birthDate" label="Data urodzenia" [controlError]="this.birthDateError" />
      <app-checkbox name="agreement">
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
        <app-checkbox-agreement label="agreement link">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi non tempor libero. Sed non nisi tincidunt, venenatis
            risus ac, pulvinar nisl. Nullam dignissim nibh felis, sed consequat magna imperdiet et. Etiam sit amet pellentesque
            magna. Aenean a erat at nibh placerat auctor at vitae nisi. Nunc nec dui quis leo dapibus dignissim. Etiam vitae
            eros tempor, pharetra dui pharetra, finibus neque. Pellentesque elit est, scelerisque vitae libero id, suscipit
            elementum libero. Sed eget purus at turpis faucibus fermentum vitae a urna. Vestibulum pretium facilisis ipsum, sit
            amet elementum ante fringilla
          </p>
          <p>
            Aliquam pharetra leo sed scelerisque sollicitudin. Vestibulum ornare luctus augue, non fringilla sem gravida ac.
            Etiam ut felis ac augue vulputate mollis quis ut libero. Duis ac feugiat orci. Suspendisse finibus risus ac mi
            dapibus vulputate. Praesent efficitur iaculis dui vel euismod. Maecenas rutrum mollis varius. Mauris vitae diam eget
            leo dictum facilisis. Suspendisse imperdiet maximus convallis.
          </p>
        </app-checkbox-agreement>
      </app-checkbox>
    </div>
    <button #submitButton type="submit" class="btn btn-secondary btn-text-shadow mt-auto">dalej</button>
    <p class="text-center mt-2">
      <a routerLink="/register/finished" class="text-brand-medium font-bold btn-text-shadow underline">pomiń</a>
    </p>
  </form>
</app--unauthorized-base>
