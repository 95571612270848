import {UserTokenInterface} from '../_interfaces/user-token.interface';

export class User implements UserTokenInterface {
  public email: string = '';

  storeToken(token:string){
    localStorage.setItem('user_token', token);
  }

  getToken() {
    return localStorage.getItem('user_token') ?? null;
  }
}
