import { Component } from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {PageTitleComponent} from '../page-title/page-title.component';
import {FormControlTextFieldComponent} from '../../common/form-control-text-field/form-control-text-field.component';
import {FormBuilder, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {CustomerServices} from '../../_services/customer.services';
import {FormAlertComponent} from '../../common/form-alert/form-alert.component';
import {NgIf} from '@angular/common';
import {Router, RouterLink} from '@angular/router';

@Component({
  selector: 'app-invite',
  standalone: true,
  imports: [
    PageTitleComponent,
    BaseComponent,
    FormControlTextFieldComponent,
    FormsModule,
    ReactiveFormsModule,
    FormAlertComponent,
    NgIf,
    RouterLink
  ],
  templateUrl: './invite.component.html',
  styleUrl: './invite.component.css'
})
export class InviteComponent {
  public inviteForm: any;
  public inviteFormSuccess: boolean = false;
  public submitted: boolean = false;
  public firstName: string = '';
  public surName: string = '';

  constructor(formBuilder: FormBuilder, private customerService: CustomerServices,public route: Router) {
    this.inviteForm = formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    })
  }

  public send() {
    if (this.inviteForm.valid) {
      this.submitted = true;
      let values = this.inviteForm.value;
      this.customerService.postInvite(values).subscribe({
        next: data => {
          this.inviteFormSuccess = true;
          this.firstName = values['name'];
          this.surName = values['surname'];
        }
      })
    }
  }
  reloadCurrentRoute() {
    const currentUrl = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([currentUrl]);
    });
  }
}
