import {Component, ElementRef, Input, viewChild} from '@angular/core';
import {RouterLink} from '@angular/router';
import {NotificationsResponse} from '../../_interfaces/notifications.response';
import {ThemingComponent} from '../../common/theming/theming.component';

@Component({
  selector: 'app--authorized-base-user',
  standalone: true,
  imports: [
    RouterLink,
    ThemingComponent
  ],
  templateUrl: './base-user.component.html',
  styleUrl: './base-user.component.css'
})
export class BaseUserComponent {
  protected menuDrawer = viewChild<ElementRef>('menuDrawer');
  protected menuDrawerEl: HTMLElement | undefined;
  @Input() headerClasses = '';
  @Input() pageWrapperClasses = '';
  public data!: NotificationsResponse;
  private interval: any;

  constructor() {
  }

  ngOnInit() {
    this.menuDrawerEl = this.menuDrawer()!.nativeElement as HTMLElement;
  }

  toggleMenu(event: Event) {
    (event.currentTarget as HTMLElement).classList.toggle('menu-active');
    this.menuDrawerEl!.classList.toggle('translate-x-full');
  }
}
