import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {PageTitleComponent} from '../page-title/page-title.component';
import {FormControlTextFieldComponent} from '../../common/form-control-text-field/form-control-text-field.component';
import {FormBuilder, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {CustomerServices} from '../../_services/customer.services';
import {FormAlertComponent} from '../../common/form-alert/form-alert.component';
import {NgForOf, NgIf} from '@angular/common';
import {InvoicesService} from '../../_services/invoices.service';
import {InvoicesResponse} from '../../_interfaces/invoices.response';
import moment from 'moment/moment';
import {NgxPaginationModule} from 'ngx-pagination';

@Component({
  selector: 'app-invoices',
  standalone: true,
  imports: [
    PageTitleComponent,
    BaseComponent,
    NgForOf,
    NgIf,
    NgxPaginationModule
  ],
  templateUrl: './invoices.component.html',
  styleUrl: './invoices.component.css'
})
export class InvoicesComponent implements OnInit {
  public invoices: InvoicesResponse[] = [];
  public showPositions: any = {};
  public page = 1;
  public totalItems: number = 0;

  constructor(
    private invoiceServices: InvoicesService,
    private customerServices: CustomerServices,
  ) {
  }

  ngOnInit(): void {
    this.updateItems();
  }

  protected readonly moment = moment;

  togglePosition(param: string) {
    this.showPositions[param] = !this.showPositions[param];
  }

  onPageChange($event: number) {
    this.page = $event
    this.updateItems();
  }

  private updateItems() {
    this.invoiceServices.getInvoices(this.customerServices.getCustomer(), null, this.page).subscribe(invoices => {
      this.invoices = invoices["hydra:member"];
      this.totalItems = invoices["hydra:totalItems"];
    });
  }
}
