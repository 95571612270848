<app--authorized-base pageWrapperClasses="welcome-page">
  <div *ngIf="this.data" class="bg-white w-[52rem] rounded-2xl mb-10 px-8 flex flex-col justify-center gap-y-5 pb-20 max-w-full">
    <app-page-title class="pt-6 pb-0" label="Powiadomienia" routerLinkUrl="/welcome"/>
    @for (i of this.data.items; track $index) {
      <a (click)="read(i.id)" *ngIf="i.unread" class="mx-4 flex h-[142px] bg-brand-medium shadow-[0px_0px_25px_#0000001A] rounded-2xl py-5 px-7 cursor-pointer">
        <div class="grow">
          <p class="first-line:font-bold text-white">
            {{i.title}}
          </p>
          <p class="text-white">
            {{ i.content }}
          </p>
          <br />
          <p class="text-white">
            {{ i.datetime }}
          </p>
        </div>
        <div class="shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="22.751" height="26" viewBox="0 0 22.751 26" class="fill-white">
            <path d="M11.393,0A1.623,1.623,0,0,0,9.768,1.625V2.6a8.129,8.129,0,0,0-6.5,7.963v.955A9.774,9.774,0,0,1,.805,18l-.376.421a1.625,1.625,0,0,0,1.214,2.707h19.5a1.625,1.625,0,0,0,1.214-2.707L21.981,18a9.764,9.764,0,0,1-2.463-6.48v-.955a8.129,8.129,0,0,0-6.5-7.963V1.625A1.623,1.623,0,0,0,11.393,0Zm2.3,25.051a3.251,3.251,0,0,0,.95-2.3h-6.5a3.253,3.253,0,0,0,5.55,2.3Z" transform="translate(-0.019)"/>
          </svg>
        </div>
      </a>

      <a *ngIf="!i.unread" class="flex h-[142px] w-full bg-gray-lighter shadow-[0px_0px_25px_#0000001A] rounded-2xl py-5 px-7">
        <div class="grow">
          <p class="first-line:font-bold text-gray-dark">
            {{i.title}}
          </p>
          <p class="text-gray-dark">
            {{ i.content }}
          </p>
          <br />
          <p class="text-gray-dark">
            {{ i.datetime }}
          </p>
        </div>
        <div class="opacity-0 shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="22.751" height="26" viewBox="0 0 22.751 26" class="fill-white">
            <path d="M11.393,0A1.623,1.623,0,0,0,9.768,1.625V2.6a8.129,8.129,0,0,0-6.5,7.963v.955A9.774,9.774,0,0,1,.805,18l-.376.421a1.625,1.625,0,0,0,1.214,2.707h19.5a1.625,1.625,0,0,0,1.214-2.707L21.981,18a9.764,9.764,0,0,1-2.463-6.48v-.955a8.129,8.129,0,0,0-6.5-7.963V1.625A1.623,1.623,0,0,0,11.393,0Zm2.3,25.051a3.251,3.251,0,0,0,.95-2.3h-6.5a3.253,3.253,0,0,0,5.55,2.3Z" transform="translate(-0.019)"/>
          </svg>
        </div>
      </a>
    }
  </div>
</app--authorized-base>
