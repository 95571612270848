<app--authorized-base pageWrapperClasses="welcome-page" class="brand-qsq">
  <div class="bg-white w-[52rem] rounded-2xl mb-10 px-12 flex flex-col justify-center gap-y-5 pb-20 max-w-full">
  <app-page-title label="Paragony" routerLinkUrl="/welcome" />
  <div role="list" class="w-full divide-y divide-solid">
    <div *ngFor="let invoice of this.invoices | paginate: { itemsPerPage: 10, currentPage: this.page, totalItems: this.totalItems }" class="gap-4 pt-10">
      <div class="flex min-w-0 gap-x-4 mb-3">
        <div class="w-full">
          <p class="float-left font-semibold">Paragon nr <span class="text-sm/6 text-gray-900">{{invoice.number}}</span></p>
          <p class="float-right"><span class="text-sm/6 text-gray-900">{{ moment(invoice.sellDate).format('DD.MM.YYYY') }}</span></p>
        </div>
      </div>
      <div class="flex flex-col gap-y-2 ">
        <div *ngIf="invoice.shop.id" class="mb-3">
          Sklep {{invoice.shop.name}} <br />
          {{invoice.shop.street}}, {{invoice.shop.zip}} {{invoice.shop.city}}
        </div>
        <ul class="ml-4 list-outside list-decimal">
          <li *ngFor="let element of invoice.invoiceElements">
            <ul class="mb-6">
              <li><span class="float-left">{{ element.name }}</span> <span class="float-right">Ilość: {{element.quantity}}</span></li><br />
              <li><span class="float-left">SKU: {{element.sku}}</span> <span class="float-right">{{element.quote}} zł</span></li><br />
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <p>Strona: <pagination-controls (pageChange)="onPageChange($event)" previousLabel="Poprzednia" nextLabel="Następna"></pagination-controls></p>
  </div>
</app--authorized-base>
