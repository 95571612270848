import {UserTokenInterface} from '../_interfaces/user-token.interface';

export class Customer implements UserTokenInterface {
  public email: string = '';
  public name: string = '';
  public id: string = '';
  public clubId: number = 0;

  storeToken(token:string){
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token') ?? null;
  }
}
