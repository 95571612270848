<div #qrHeader class="max-h-48">
  <div class="absolute top-0 left-0 w-full h-[510px] -z-10">
  </div>
  <div class="float-left pr-10 pb-10">
    <canvas #qrCode id="myCanvas" width="150" height="150" class="scale-100 origin-top transition-all aspect-square"></canvas>
  </div>
  <div class="float-left pt-5">
    <h1 class="text-2xl font-bold text-white mb-3">Cześć {{ this.user.name }}!</h1>
    <h2 class="text-white font-light mb-7 text-xl">Pokaż swój kod sprzedawcy<br /> aby skorzystać z promocji</h2>
  </div>
</div>
