import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpQueryBuilder {
  private params:URLSearchParams;

  constructor() {
    this.params = new URLSearchParams();
  }

  public add(key: string, value: string) {
    this.params.append(key, value);
  }

  public build() {
    let params = this.params.toString();

    if (params.length > 0) {
      params = '?' + params;
    }

    return params;
  }
}
