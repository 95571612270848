import {Component, ElementRef, OnInit, viewChild} from '@angular/core';
import { BaseComponent } from '../base/base.component';
import {Router} from '@angular/router';
import {AuthServices} from '../../_services/auth.services';
import {NgIf} from '@angular/common';
import {ConfirmPhoneResponse} from '../../_interfaces/confirm.phone.response.interface';
import {PinPadComponent} from '../../common/pin-pad/pin-pad.component';
import {CustomerServices} from '../../_services/customer.services';

@Component({
  selector: 'app-auth-code',
  standalone: true,
  imports: [
    BaseComponent,
    NgIf,
    PinPadComponent,
  ],
  templateUrl: './auth-code.component.html',
  styleUrl: './auth-code.component.css'
})
export class AuthCodeComponent implements OnInit {

  protected submitButton = viewChild<ElementRef>('submitButton');
  protected readonly Array = Array;
  protected isSubmitted = false;
  public phone: string = '';
  public email: string = '';
  public resendAuthCodeStatus = false;
  public session: any;
  private authCode: any;
  public pinPadValue: string = '';
  public pinPadError: string = '';
  fullValidation: any;

  constructor(private router: Router, public authServices: AuthServices, private customerService: CustomerServices) {
  }
  ngOnInit(): void {
    this.session = JSON.parse(sessionStorage.getItem('registration') ?? '{}');
    this.phone = this.session.phone ?? '';
    this.email = this.session.email ?? '';
    this.authCode = this.session.authCode ?? false;

      if (this.phone === '' && this.email === '') {
        this.router.navigate(['register']);
      }
      if (this.authCode) {
        this.router.navigate(['register/finalize']);
      }
  }

  submit(event: SubmitEvent) {
    event.preventDefault();
    this.fullValidation = true;
    if (this.pinPadError === '') {
      this.submitButton()!.nativeElement.classList.toggle('opacity-30');
      this.isSubmitted = true;

      if (this.phone !== '') {
        this.authServices.confirmPhone({phone: this.phone, pin: this.pinPadValue}).subscribe({
            next: (data: ConfirmPhoneResponse)  => {
              this.submitButton()!.nativeElement.classList.toggle('opacity-30');
              this.session.authCode = true;
              sessionStorage.setItem('token', data.token);
              sessionStorage.setItem('customerId', data.customerId);
              sessionStorage.setItem('registration', JSON.stringify(this.session));
              this.authServices.storeToken(data.token);
              this.customerService.loginWithToken(data.token);
              this.router.navigate(['register/finalize']);
            },
            error: error => {
              this.submitButton()!.nativeElement.classList.toggle('opacity-30');
              this.pinPadError = 'Kod weryfikacyjny jest niepoprawny';
              this.isSubmitted = false;
            }
          }
        );
      } else {
        this.authServices.confirmEmail({email: this.email, pin: this.pinPadValue}).subscribe({
            next: (data: ConfirmPhoneResponse)  => {
              this.submitButton()!.nativeElement.classList.toggle('opacity-30');
              this.session.authCode = true;
              sessionStorage.setItem('token', data.token);
              sessionStorage.setItem('customerId', data.customerId);
              sessionStorage.setItem('registration', JSON.stringify(this.session));
              this.authServices.storeToken(data.token);
              this.customerService.loginWithToken(data.token);
              this.router.navigate(['register/finalize']);
            },
            error: error => {
              this.submitButton()!.nativeElement.classList.toggle('opacity-30');
              this.pinPadError = 'Kod weryfikacyjny jest niepoprawny';
              this.isSubmitted = false;
            }
          }
        );
      }
    }
  }

  resendAuthCode() {
    this.authServices.resendAuthCode({emailOrPhone: this.phone}).subscribe({
      next: data => {
        this.resendAuthCodeStatus = true;
        setTimeout(() => {
          this.resendAuthCodeStatus = false;
        }, 60000);
      }
    })
  }
}
