<app-theming>
  <div id="page-wrapper" [class]="pageWrapperClasses" class="relative min-h-screen flex flex-col brand-qsq md:items-center">
  <header id="header--logged-in" [class]="headerClasses" class="md:w-[56rem] md:top-0 px-8 pt-20 pb-12 flex justify-between bg-brand-dark drop-shadow-lg z-50">
    <a routerLink="/welcome">
      <div>
        <img src="/assets/images/logo-concierge.svg" />
      </div>
    </a>

    <div class="flex items-center gap-x-5">
      <a routerLink="/notifications">
        <!-- messages bell -->
        <div class="relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="33.93" height="27.158" viewBox="0 0 33.93 27.158" class="fill-white">
            <defs>
              <clipPath >
                <rect width="33.93" height="27.158" />
              </clipPath>
            </defs>
            <path d="M11.9,0a1.7,1.7,0,0,0-1.7,1.7V2.716a8.491,8.491,0,0,0-6.79,8.317v1A10.209,10.209,0,0,1,.84,18.8l-.393.44a1.7,1.7,0,0,0,1.268,2.827H22.084a1.7,1.7,0,0,0,1.268-2.827l-.393-.44a10.2,10.2,0,0,1-2.573-6.768v-1A8.491,8.491,0,0,0,13.6,2.716V1.7A1.7,1.7,0,0,0,11.9,0Zm2.4,26.166a3.4,3.4,0,0,0,.992-2.4H8.505a3.4,3.4,0,0,0,5.8,2.4Z" transform="translate(10.147)" opacity="0.8"/>
          </svg>

          <span *ngIf="this.data" class="absolute bottom-0 left-0 bg-white/80 rounded-full text-xs w-4 h-4 flex justify-center items-center">{{ this.data.unreadItems }}</span>
        </div>
      </a>

      <!-- menu burger -->
      <button (click)="toggleMenu($event)" class="burger-menu relative w-8 h-7 flex flex-col justify-between" aria-haspopup="true" aria-controls="menu">
        <span class="block w-full h-1 bg-white rounded-xl relative transition-all"></span>
        <span class="block w-full h-1 bg-white rounded-xl relative transition-all"></span>
        <span class="block w-full h-1 bg-white rounded-xl relative transition-all"></span>
      </button>

    </div>
  </header>

    <!-- drawer -->
    <div #menuDrawer class="menu-drawer fixed top-[146px] bottom-0 left-0 w-full h-full min-h-screen bg-gradient-to-b from-brand-dark to-brand-light md:px-72 py-3 transition-transform translate-x-full z-50">
      <nav role="menu" aria-labelledby="menubutton" class="flex flex-col text-white">
        <a role="menuitem" routerLink="/welcome" class="py-5 border-b border-b-white/30 text-lg pl-7">Ekran główny</a>
        <a role="menuitem" routerLink="/brands" class="py-5 border-b border-b-white/30 text-lg pl-7">Nasze marki</a>
        <a role="menuitem" href="https://odbierzniespodzianke.pl/" class="py-5 border-b border-b-white/30 text-lg pl-7">
          <img src="/assets/images/niespodzianka.svg" alt="Odbierzniespodzianke.pl"/>
        </a>
        <a role="menuitem" routerLink="/invoices" class="py-5 border-b border-b-white/30 text-lg pl-7">Paragony</a>
        <a role="menuitem" routerLink="/details-change" class="py-5 border-b border-b-white/30 text-lg pl-7">Zmiana danych</a>
        <a role="menuitem" routerLink="/consents" class="py-5 border-b border-b-white/30 text-lg pl-7">Zgody marketingowe</a>
        <a role="menuitem" routerLink="/invite" href="#" class="py-5 border-b border-b-white/30 text-lg pl-7">Zaproś do klubu</a>
      </nav>
    </div>
    <main class="flex flex-col items-center grow">
      <ng-content/>
    </main>
  </div>
</app-theming>
