import {Component, ElementRef, viewChild} from '@angular/core';
import {EventManager} from '@angular/platform-browser';

@Component({
  selector: 'app-theming',
  standalone: true,
  imports: [],
  templateUrl: './theming.component.html',
  styleUrl: './theming.component.css'
})
export class ThemingComponent {
  protected brand = viewChild<ElementRef>('brand');
  protected brandEl!: HTMLElement;
  private brands = ["qsq", "blonie", "akardo", "marasimsim", "focus", "larosa"];
  constructor(private eventManager: EventManager) {
  }

  ngOnInit() {
    this.brandEl = this.brand()!.nativeElement;
    this.eventManager.addEventListener(document.body, "changeTheme", (event: CustomEvent)=>{this.changeTheme(event, this.brandEl)});
  }

  changeTheme(event: CustomEvent, themeElement: HTMLElement) {
    const newTheme = event.detail.theme;
    if (this.brands.includes(newTheme)) {
      themeElement.className = "brand-" + newTheme;
    } else {
      console.error("Brand '" + newTheme + "' is not defined in themes");
    }
  }
}
